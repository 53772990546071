// Nomadix SCSS mixins
//
//
@use "sass:color";

//
// Generate a single CSS color variable expressed either as an
// absolute hex color value or as raw R, G, B component values.
@mixin ez-color-var($name, $color, $frag: false) {
  @if $frag {
    @include ez-color-var-rgb-frag($name, $color);
  } @else {
    #{$name}: $color;
  }
}

//
// Generate a color variable named $name + "-rgb" whose value is the
// comma-separated RGB fragments of $color.
//
// The resultant variables are ONLY SAFE FOR USE in CSS rgb() or
// rgba() function calls. They CANNOT be used as absolute color values!
//
// e.g. rgb(var($frag-name));       # Good
// or   rgba(var($frag-name), 0.2); # Good
// but  color: var($frag-name);     # Bad!
//
@mixin ez-color-var-rgb-frag($name, $color) {
  #{$name + "-rgb"}: color.red($color), color.green($color), color.blue($color);
}

@mixin ez-color-var-scale($name, $color, $frag: false) {
  @each $suffix,
    $scale
      in ("100": -80%, "200": -60%, "300": -40%, "400": -20%, "500": +0%, "600": 20%, "700": 40%, "800": 60%, "900": 80%)
  {
    @include ez-color-var(
      #{$name + "-" + $suffix},
      color.scale($color, $red: $scale, $green: $scale, $blue: $scale),
      $frag
    );
  }
}

//
// Generate a series of color variables from a (name: color) map.
//
@mixin ez-color-vars($map, $scale: false) {
  @each $name, $color in $map {
    @include ez-color-var($name, $color);
    @if $scale {
      @include ez-color-var-scale($name, $color, $frag: false);
    }
  }
}

@mixin ez-color-var-frags($map, $scale: false) {
  @each $name, $color in $map {
    @include ez-color-var-rgb-frag($name, $color);
    @if $scale {
      @include ez-color-var-scale($name, $color, true);
    }
  }
}

@mixin ez-override-bs-color-classes($theme-colors: $theme-colors) {
  @each $name, $color in $theme-colors {
    .badge-#{$name} { background-color: var(#{"--bs-"+$name}); }
    .bg-#{$name} { background-color: var(#{"--bs-"+$name}); }
    .border-#{$name} { border-color: var(#{"--bs-"+$name}); }
    .btn-#{$name} {
      background-color: var(#{"--bs-"+$name});
      border-color: var(#{"--bs-"+$name});
      &:not(:disabled):not(.disabled):active, &:hover { background-color: var(#{"--bs-"+$name}); }
    }
    .btn-outline-#{$name},  .btn-outline-#{$name}:hover {
      color: var(#{"--bs-"+$name});
      border-color: var(#{"--bs-"+$name});
      &:not(:disabled):not(.disabled):active, &:hover { background-color: var(#{"--bs-"+$name}); }
    }
    .text-#{$name} { color: var(#{"--bs-"+$name}); }
  }
}

@mixin ez-override-bs-color-classes($theme-colors: $theme-colors) {
  @each $name, $color in $theme-colors {
    .badge-#{$name} { background-color: var(--bs-#{$name}); }

    .bg-#{$name} { background-color: var(--bs-#{$name}); }

    .border-#{$name} { border-color: var(--bs-#{$name}); }

    .btn-#{$name},
    .btn-#{$name}:not(:disabled):not(.disabled):active,
    .btn-#{$name}:focus,
    .btn-#{$name}.focus,
    .btn-#{$name}:focus-visible,
    .btn-#{$name}:hover {
      background-color: var(--bs-#{$name});
      border-color: var(--bs-#{$name});
      box-shadow: none;
    }

    .btn-outline-#{$name}, .btn-outline-#{$name} {
      color: var(--bs-#{$name});
      border-color: var(--bs-#{$name});
      &:not(:disabled):not(.disabled):active, &:hover{ background-color: var(--bs-#{$name}); }
    }

    .btn-outline-#{$name}:hover{border-color: var(--bs-#{$name});}

    .text-#{$name} { color: var(--bs-#{$name}); }
  }
}

//
// This one was added to ensure that link_to elements that have been assigned the 'disabled'
// class will keep their originally-intended color. Because the CSS in bootstrap asserts
// an !important qualifier that refers to the original color rgb instead of the color
// variable.
@mixin ez-assert-theme-colors($theme-colors: $theme-colors) {
  @each $name, $color in $theme-colors {
    &.btn-#{$name}:not(:hover) {
      background-color: var(--bs-#{$name}) !important;
    }

    &.btn-outline-#{$name}:not(:hover),
    .text-#{$name} {
      color: var(--bs-#{$name}) !important;
    }
  }
}