@use "sass:meta" as *;
body {
  font-size: 1.6em;
}

.alert {
  color: red;
}

.notice {
  color: green;
}

.invalid-feedback {
  text-align: right;
  margin-right: 8px;
}

.unit-a101-calendar-li .form-group .input-group .input-group-append i {
  font-size: 20px;
  padding-right: 14px;
}

tr.toggle-example {
  display: none;

  &.active {
    display: table-row;
  }
}

// Google material fonts added here for all branding //
@include load-css("ez-google-material-fonts");
