//
// Nomadix SCSS post-bootstrap brand customization adjustments
//
// This SCSS script should be used to generate brand-specific customizations for
// core ezwifi.css package, but also for _standalone_ branded CSS packages that can be
// loaded AFTER ezwifi.css
//
// Note that standalone brand-based builds should import this file after providing
// overrides for various notable variables.
//
@use "sass:map";
@use "ez-post-strap";
@use "ez-theming-vars";

//
// Here we encapsulate what we need to change for pre-scripted brands
$ez-known-brands: (
  nomadix:          ( prm: ez-post-strap.$ez-standard-primary,  sec: ez-post-strap.$ez-standard-secondary, lk: none, bread: none,
                      logo: ez-post-strap.$ez-standard-logo, logo-h: 80px, logo-w: 80px,
                      bg: none),

  ambit:            ( prm: rgb(238, 171, 27),   sec: rgb(135, 187, 70), lk: rgb(188, 120, 0), bread: rgb(238, 170, 27),
                      logo: "logo-ambit.png", logo-h: 81px, logo-w: 153px, bg: none),

  apogee:           ( prm: rgb(0, 75, 121),     sec: rgb(0, 102, 168), lk: rgb(0, 75, 121), bread: rgb(0, 75, 121),
                      logo: "logo-apogee-70.png", logo-h: 36px, logo-w: 200px,
                      bg: "background-apogee.png"),

  blmwifi:          ( prm: rgb(102, 137, 175),  sec: rgb(0, 59, 122), lk: rgb(255, 111, 89), bread: rgb(102, 137, 175),
                      logo: "logo-blmwifi-250.png", logo-h: 81px, logo-w: 250px,
                      bg: "background-blmwifi-1000.png"),

  cambium:          ( prm: rgb(9, 41, 80),      sec: rgb(0, 102, 168), lk: rgb(9, 41, 80), bread: rgb(9, 41, 80),
                      logo: "logo-cambium-250.png", logo-h: 41px, logo-w: 250px, bg: none),

  cisco:            ( prm: rgb(4, 159, 217),    sec: rgb(13, 39, 77), lk: rgb(4, 159, 217), bread: rgb(13, 39, 77),
                      logo: "logo-cisco-70.png", logo-h: 70px, logo-w: 132px, bg: none),

  cloud5:           ( prm: rgb(2, 161, 226),    sec: rgb(0, 102, 168), lk: rgb(2, 161, 226), bread: rgb(0, 111, 176),
                      logo: "logo-cloud5-70.png", logo-h: 70px, logo-w: 300px,
                      bg: "background-cloud5.png", bg-min-h: 836px),

  collective:       ( prm: rgb(29, 70, 91),     sec: rgb(3, 38, 34), lk: rgb(12, 146, 8), bread: rgb(29, 70, 91),
                      logo: "logo-collective-250.png", logo-h: 74px, logo-w: 250px,
                      bg: "background-collective-oldoak.png"),

  dpsystems:        ( prm: rgb(110, 42, 141),   sec: rgb(113, 112, 116), lk: rgb(110, 42, 141), bread: rgb(110, 42, 141),
                      logo: "logo-dpsystems-200.png", logo-h: 46px, logo-w: 200px, bg: none),

  fantasyworld:     ( prm: rgb(204, 139, 60),   sec: rgb(154, 89, 10), lk: rgb(79, 112, 68), bread: rgb(79, 112, 68),
                      logo: "logo-fantasyworld-resort-160.png", logo-h: 90px, logo-w: 160px,
                      bg: "background-fantasyworld-resort.jpg"),

  greenwoodannex:   ( prm: rgb(0, 121, 76),     sec: rgb(36, 31, 37), lk: rgb(12, 146, 8), bread: rgb(0, 121, 76),
                      logo: "logo-camden-250.png", logo-h: 54px, logo-w: 250px,
                      bg: "background-camden-lock.jpg"),

  harrow:           ( prm: rgb(29, 70, 91),     sec: rgb(3, 38, 34), lk: rgb(12, 146, 8), bread: rgb(29, 70, 91),
                      logo: "logo-collective-250.png", logo-h: 74px, logo-w: 250px,
                      bg: "background-collective-harrow.png"),

  integratednet:    ( prm: rgb(0, 51, 73),      sec: rgb(50, 101, 123), lk: rgb(138, 36, 50), bread: rgb(138, 36, 50),
                      logo: "logo-integratednet.png", logo-h: 64px, logo-w: 122px),

  intertouch:       ( prm: rgb(0, 108, 255),    sec: rgb(26, 30, 87), lk: rgb(138, 36, 50), bread: rgb(0, 108, 255),
                      logo: "logo-intertouch.png", logo-h: 65px, logo-w: 253px, bg: none),

  legacydunes:      ( prm: rgb(204, 139, 60),   sec: rgb(154, 89, 10), lk: rgb(79, 112, 68), rgb(79, 112, 68): none,
                      logo: "logo-legacydunes-250.png", logo-h: 40px, logo-w: 250px,
                      bg: "background-legacydunes.png"),

  lejames01:        ( prm: rgb(50, 50, 50),     sec: rgb(87, 61, 23), lk: rgb(50, 50, 50), bread: rgb(37, 11, 0),
                      logo: "logo-lejames-80.png", logo-h: 80px, logo-w: 136px,
                      bg: "background-lejames.jpg", bg-min-h: 836px, bg-opac: .25),

  ndx_cloud:        ( prm: rgb(110, 175, 67),   sec: rgb(8, 83, 148), lk: none, bread: none,
                      logo: "logo-nomadix-cloud.svg", logo-h: 5rem, logo-w: 8rem, bg: none),

  purdicom:         ( prm: rgb(71, 166, 255),   sec: rgb(21, 116, 205), lk: rgb(255, 111, 89), bread: rgb(71, 166, 255),
                      logo: "logo-purdicom-250.png", logo-h: 92px, logo-w: 250px, bg: none),

  safetynetaccess:  ( prm: rgb(138, 36, 50),    sec: rgb(41, 46, 54), lk: rgb(138, 36, 50), bread: rgb(138, 36, 50),
                      logo: "logo-safetynetaccess-50.png", logo-h: 50px, logo-w: 156px, bg: none),

  spectrum:         ( prm: rgb(0, 48, 87),      sec: rgb(0, 153, 216), lk: rgb(250, 164, 26), bread: rgb(0, 153, 216),
                      logo: "logo-spectrum-cs.png", logo-h: 33px, logo-w: 242px, bg: none),

  stilltech:        ( prm: rgb(50, 138, 64),    sec: rgb(105, 95, 86), lk: rgb(50, 138, 64), bread: rgb(50, 138, 64),
                      logo: "logo-stilltech-a-100.png", logo-h: 65px, logo-w: 104px, bg: none),

  syntrex:          ( prm: rgb(3, 102, 2),      sec: rgb(3, 102, 2), lk: rgb(3, 102, 2), bread: rgb(3, 102, 2),
                      logo: "logo-syntrex.png", logo-h: 60px, logo-w: 250px, bg: none),

  telus:            ( prm: rgb(43, 128, 0),     sec: rgb(75, 40, 109), lk: rgb(102, 204, 0), bread: rgb(75, 40, 109),
                      logo: "logo-telus-250.png", logo-h: 51px, logo-w: 250px, bg: none),

  wanaport:         ( prm: rgb(0, 16, 71),      sec: rgb(64, 84, 178), lk: rgb(15, 188, 245), bread: rgb(64, 84, 178),
                      logo: "logo-wanaport-70.png", logo-h: 70px, logo-w: 171px, bg: none),
);

// Lets set these since they are conditional below
$ez-bg-min-height: ez-post-strap.$ez-bg-min-height;
$ez-bg-repeat: ez-post-strap.$ez-bg-repeat;
$ez-bg-origin: ez-post-strap.$ez-bg-origin;
$ez-bg-blend-mode: ez-post-strap.$ez-bg-blend-mode;
//
// Generate root color variables
:root {
  @include ez-theming-vars.ez-generate-css-vars();
}

//
// All of these customizations will be tied to a body element marked with a class
// named for the schema. That is what opens the gate.
@each $schema, $settings in $ez-known-brands {
  //
  // First thing we do is set-up some default values for SASS variables that we might be tweaking...
  // This is to clean the sheet, as it were, at each iteration.
  $ez-primary-color: map.get($settings, prm);
  $ez-secondary-color: map.get($settings, sec);

  $ez-brand-image: url("../images/#{ez-post-strap.$ez-standard-logo}");
  $ez-brand-min-width: 250px;
  $ez-brand-min-height: 80px;
  $ez-brand-bg-size: contain;

  $ez-bg-image: none;
  $ez-bg-size: cover;
  $ez-bg-position: top left;
  $ez-bg-opacity: .4;

  //
  // Fetch logo customizations, and use them to overwrite SCSS variables
  @if map.has-key($settings, logo) and map.get($settings, logo) != none {
    $ez-brand-image: url("../images/#{map.get($settings, logo)}");

    @if map.has-key($settings, logo-h) {
      $ez-brand-min-height: map.get($settings, logo-h);
    }

    @if map.has-key($settings, logo-w) {
      $ez-brand-min-width: map.get($settings, logo-w);
    }
  }

  //
  // Fetch background image customizations and use them to overwrite SACS variables.
  @if map.has-key($settings, bg) and map.get($settings, bg) != none {
    $ez-bg-image: url("../images/#{map.get($settings, bg)}");

    @if map.has-key($settings, bg-sz) {
      $ez-bg-size: map.get($settings, bg-sz);
    }

    @if map.has-key($settings, bg-min-h) {
      $ez-bg-min-height: map.get($settings, bg-min-h);
    }

    @if map.has-key($settings, bg-rpt) {
      $ez-bg-repeat: map.get($settings, bg-rpt);
    }

    @if map.has-key($settings, bg-org) {
      $ez-bg-origin: map.get($settings, bg-org);
    }

    @if map.has-key($settings, bg-pos) {
      $ez-bg-position: map.get($settings, bg-pos);
    }

    @if map.has-key($settings, bg-blm) {
      $ez-bg-blend-mode: map.get($settings, bg-blm);
    }

    @if map.has-key($settings, bg-opac) {
      $ez-bg-opacity: map.get($settings, bg-opac);
    }
  }


  :root.schema-#{$schema} {
    //
    // Modify important color variables with branded values
    @each $name, $key in (--bs-primary: prm, --bs-secondary: sec, --ez-link-color: lk, --ez-breadcrumb-link: bread) {
      @if map.has-key($settings, $key) and map.get($settings, $key) != none {
        @include ez-post-strap.ez-color-var($name, map.get($settings, $key));
        @include ez-post-strap.ez-color-var-scale($name, map.get($settings, $key));
      }
    }
    // $adjustments: (--bs-primary: map-get($settings, prm), --bs-secondary: map-get($settings, sec), --ez-breadcrumb-link: map-get($settings, prm));
    // @include ez-color-vars($adjustments, true);

    .ez-container:not(.plain)::before {
      min-height: $ez-bg-min-height;

      background-image: $ez-bg-image;
      background-size: $ez-bg-size;
      background-repeat: $ez-bg-repeat;
      background-origin: $ez-bg-origin;
      background-position: $ez-bg-position;
      background-blend-mode: $ez-bg-blend-mode;
      opacity: $ez-bg-opacity;

    }

    .ez-container {
      background-color: var(--ez-main-bg);
      .ez-banner {
        .top-bar-logo {
          background-image: $ez-brand-image;
          background-size: $ez-brand-bg-size;
          min-height: $ez-brand-min-height;
          min-width: $ez-brand-min-width;
        }
      }
    }
  }
}
