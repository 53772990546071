/*! CC Styles SCSS Indirect inclusion
    =================================

    This file is a conversion of Custom Creative's 'Resident Page' SASS source for EzWifi,
    now in SCSS guise.

*/
@use "legacy-variables.scss";

.wrapper {
  background-color: legacy-variables.$mainBackground;
  min-height: 50rem;
  padding-bottom: 7.5rem;
  width: 100%;

  .top-bar {
    background-color: legacy-variables.$topBarBackground;
    padding: 1.5rem 0;
    box-shadow: 0 0.9rem 1.5rem rgba(0, 0, 0, 0.2);

    .top-bar-logo {
      //background-image: $topBarLogo;
      background-repeat: no-repeat;
      background-size: legacy-variables.$topBarLogoSize;
      margin-bottom: 0;
      background-position-x: 1.5rem;
      min-height: 5rem;
    }

    .top-bar-icons {
      float: right;
      display: flex;
      justify-content: flex-end;

      span {
        display: inline-block;
        text-align: center;
        font-size: legacy-variables.$topBarFontSize;
        margin: 0 1rem;

        img {
          width: 3.3rem;
        }
      }

      p {
        margin: 0;
      }
    }
  }
}

.wrapper {
  .modal {
    padding-top: 2%;
    overflow: initial;
    overflow-y: scroll;

    .modal-content {
      border: 0;
      box-shadow: none;
      border-radius: 1.5rem;

      .modal-header {
        text-align: left;
        padding-bottom: 0;
        position: relative;
        border: 0;
        font-size: 2.2rem;
        color: legacy-variables.$expandTitleColor;
      }

      .modal-body {
        h6 {
          text-align: left;
          font-size: 1.8rem;
          color: legacy-variables.$textColor;
          margin-bottom: 0.5rem;
        }

        p {
          padding: 0 1rem;
        }

        ul {
          display: inline-block;
          list-style: none;
          float: left;

          li {
            min-width: 8rem;
            text-align: left;
            color: legacy-variables.$textColor;
            margin-right: 1rem;
            padding-left: 1rem;
          }
        }
      }

      .modal-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 0;
        border: 0;

        button {
          color: legacy-variables.$saveButtonColor !important;
          background-color: legacy-variables.$saveButtonBackground !important;
          padding: 0.5rem 1.5rem;
          padding: 0.5rem 2rem;
          border-radius: 0.7rem;
        }
      }
    }
  }
}

.wrapper {
  .modal {
    .modal-content {
      .reset-wifi-modal-header {
        position: relative;
      }

      .reset-wifi-modal-body {
        form[name="change-wifi-password-personal"] {
          ul {
            margin-bottom: 2.5rem;

            li {
              label {
                p {
                  color: legacy-variables.$personalDarkened !important;
                }
              }
            }
          }
        }

        form[name="change-wifi-password-guest"] {
          ul {
            margin-bottom: 2.5rem;

            li {
              label {
                p {
                  color: legacy-variables.$guestDarkened !important;
                }
              }
            }
          }
        }

        .guest-color {
          color: legacy-variables.$guestDarkened !important;
          display: inline-block;
          margin: 0;
          padding: 0;
          font-style: normal !important;
        }

        .personal-color {
          color: legacy-variables.$personalDarkened !important;
          display: inline-block;
          margin: 0;
          padding: 0;
          font-style: normal !important;
        }

        b.warning {
          color: legacy-variables.$cautionTitleColor;
          font-size: legacy-variables.$warningTextSize;
          text-transform: uppercase;
          display: block;
          text-align: center;
          margin-bottom: 1rem;
        }

        form {
          ul {
            li {
              display: block;
              width: auto;
              max-width: initial !important;

              label {
                font-weight: 400;
                margin: 0;
              }

              p {
                display: inline-block;
                margin: 0;
                padding-left: 0;
                color: legacy-variables.$personalDarkened;
              }
            }
          }
        }
      }

      .reset-wifi-modal-footer {
        flex-direction: column;
        width: 100%;
        max-width: 128rem;

        button {
          display: block;
          width: 100%;
          max-width: 54rem;
          margin: 1rem 0;
          padding-left: 0 !important;
          padding-right: 0 !important;
          text-align: center;
          font-size: legacy-variables.$modalButtonTextSize;
        }

        .orange-btn {
          background-color: legacy-variables.$cautionTitleColor !important;
        }
      }
    }
  }
}

.wrapper {
  .interior-content {
    max-width: 114rem;

    .main-title {
      color: legacy-variables.$mainTitleColor;
      text-align: center;
      margin-top: 2.5rem;
      margin-bottom: 1.5rem;

      h2 {
        font-size: 2rem;
      }
    }

    .caution {
      border: 0.2rem solid legacy-variables.$cautionTitleColor;
      position: relative;
      padding: 0;
      border-radius: 0.7rem;
      overflow: hidden;
      min-height: 10rem;
      margin: 1.5rem 0;
      margin-bottom: 3rem !important;

      .caution-title {
        background-color: legacy-variables.$cautionTitleBackground;

        h5 {
          color: legacy-variables.$cautionTitleColor;
          font-size: 1.6rem;
          margin: 0.6rem 0;
          font-weight: bold;
          text-align: center;
        }
      }

      .caution-content {
        background-color: legacy-variables.$cautionTextBackground;

        p {
          padding: 0.5rem;
          margin: 0;
          color: legacy-variables.$cautionTextColor !important;
        }
      }

      .caution-bg {
        background-color: #ff6d00;
        height: 100%;
        position: absolute;
        width: 100%;
        z-index: 1;
        left: 0;
      }
    }

    .wifi-settings {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      p {
        display: inline-block;
        color: legacy-variables.$textColor;
        margin: 0;
        font-size: legacy-variables.$textSize;
        margin: 0 2rem 0 0;
      }

      span {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        label {
          margin: 0;
        }

        .personal {
          color: legacy-variables.$personalDarkened;
        }

        .guest {
          color: legacy-variables.$guestDarkened;
        }

        p {
          margin: 0 1rem !important;
        }
      }
    }
  }
}

.wrapper {
  .interior-content {
    .wifi-settings {
      .switch {
        position: relative;
        display: inline-block;
        width: 6rem;
        height: 3.4rem;
      }

      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: legacy-variables.$personalDarkened;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      .slider:before {
        position: absolute;
        content: "";
        height: 2.6rem;
        width: 2.6rem;
        left: 0.4rem;
        bottom: 0.4rem;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      input:checked+.slider {
        background-color: legacy-variables.$guestDarkened;
      }

      input:focus+.slider {
        box-shadow: 0 0 0.1rem #2196f3;
      }

      input:checked+.slider:before {
        -webkit-transform: translateX(2.6rem);
        -ms-transform: translateX(2.6rem);
        transform: translateX(2.6rem);
      }

      .slider.round {
        border-radius: 3.4rem;
      }

      .slider.round:before {
        border-radius: 50%;
      }
    }
  }
}

.wrapper {
  .interior-content {
    .expandible-panel {
      .panel {
        position: relative;

        .panel-heading {
          background: legacy-variables.$expandTopBackgroundColor;
        }

        i.expand-arrow {
          position: absolute;
          right: 0.8rem;
          top: 0.2rem;
          font-size: 3.5rem;
          transition: all 250ms;
        }

        a.collapsed {
          i {
            transform: rotate(180deg);
          }
        }

        a {
          i {
            color: legacy-variables.$expandTitleColor !important;
          }
        }
      }

      .secondary-title-a {
        text-decoration: none;

        &:hover {
          text-decoration: none !important;
        }

        &:focus {
          text-decoration: none !important;
        }
      }

      .secondary-title {
        color: legacy-variables.$expandTitleColor;
        font-size: 1.8rem;
        text-decoration: none;
      }

      .panel-group {
        margin: 1.5rem 0;
      }

      .panel-heading {
        padding-right: 3.5rem;
      }

      .special-btn {
        button {
          padding: 1rem;
          padding-left: 5rem;
          position: relative;
          border-radius: 0.7rem;
          margin-top: 0;

          span {
            position: absolute;
            left: 0.1rem;
            top: 3%;
            border-radius: 0.5rem;
            height: 94%;
            overflow: hidden;

            i {
              transform: rotate(45deg);
              font-size: 3.6rem;
              margin-left: -0.5rem;
              margin-top: -0.1rem;
              margin-right: -0.1rem;
              opacity: 0.9;
            }
          }
        }
      }

      .expandible-panel-content {
        color: legacy-variables.$textColor;
        font-size: legacy-variables.$textSize;

        .col-12 {
          padding: 0;
        }

        em {
          color: legacy-variables.$emColor;
        }

        p {
          color: legacy-variables.$textColor;

          a {
            color: legacy-variables.$linkColor;
            text-decoration: underline;

            &:hover {
              text-decoration: none;
            }
          }
        }

        form {
          label {
            font-weight: 500;
          }

          .accout-information-phone-container {
            position: absolute;
            right: 1.5rem;
          }
        }

        ul {
          margin: 0;

          li {
            &:first-of-type {
              min-width: 9rem;
            }

            color: legacy-variables.$textColor;
            margin: 0.5rem 0;
            display: inline-block;
          }
        }

        .qr-code {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}

/* ------------------------------------------------------------------ */
/* -------------------------- SERVICES CSS -------------------------- */
/* ------------------------------------------------------------------ */

.top-bar-icons {
  margin-left: auto;
}

.top-bar-logo {
  a {
    height: 80%;
    width: 50%;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.main-title {
  h2 {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
}

.expandible-panel {
  .panel {
    border: 0.1rem solid #ddd;
    margin-bottom: 0;
    border-radius: 0.4rem;
    -webkit-box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 0.1rem 0.1rem rgba(0, 0, 0, 0.05);

    .panel-heading {
      padding-right: 3.5rem;
      padding: 1rem 1.5rem;
      border-top-left-radius: 0.3rem;
      border-top-right-radius: 0.3rem;

      .panel-title {
        margin-bottom: 0;
      }
    }

    .panel-body {
      padding: 1rem 1.5rem;
    }

    .panel-collapse {
      background: white;
    }
  }
}

.base-small-button {
  margin: 0 0.8rem;
  position: relative;
  border-radius: 0.7rem;
  min-width: 9rem;
  display: inline-block;
  transition: all 250ms;

  &:not(.btn-sm) {
    font-size: 1.4rem;
    padding: 0.4rem 1.5rem !important;
  }

  &:not(.btn-primary):not(.btn-secondary):not(.btn-warning):not(.btn-dark):not(.btn-outline-primary):not(.btn-outline-secondary):not(.btn-outline-warning):not(.btn-outline-dark) {
    color: #ffffff !important;
    background: #6eaf43;

    &:hover {
      color: #eee !important;
    }
  }

  span {
    position: absolute;
    left: 0.1rem;
    top: 3%;
    border-radius: 0.5rem;
    height: 94%;
    overflow: hidden;
    padding: 0.5rem;
    /* background: $personalDarkened; */
    /* color: white; */

    i {
      // transform: rotate(45deg);
      font-size: 3.6rem;
      margin-left: -0.2rem;
      margin-top: -0.1rem;
      margin-right: -0.1rem;
      opacity: 0.9;
    }
  }
}

.expandible-panel-content {
  margin: 0 !important;

  .large-text {
    max-height: 22.5rem;
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    overflow-y: scroll;
    overflow-x: hidden;
    white-space: pre-wrap;
    margin: 1.5rem 0;
    margin-top: 0.5rem;
    padding: 1rem;
    font-family: sans-serif;
    font-size: 1.4rem;
  }

  ul {
    margin: -0.6rem 0 !important;
  }
}

.expandible-panel {
  .secondary-title {
    span {
      color: legacy-variables.$cautionTitleColor;
      font-weight: bold;
    }
  }
}

.panel-body {
  .container-fluid {
    padding: 0;
  }
}

.services-options {
  display: flex;
  justify-content: center;
  align-items: center;

  .services-options-box {
    width: 50%;
    margin: 0;
    display: flex;
    align-items: center;
    flex-direction: column;

    .services-options-element {
      width: 100%;
      height: 4.6rem;
      margin: 0.8rem;
    }

    .services-options-element-btn {
      background: legacy-variables.$personalBackground;
      text-align: left;
    }

    .services-options-element-1 {
      label {
        width: 100%;
        font-size: 1.4rem;
        display: flex;
        align-items: center;

        select {
          height: 3.1rem;
          width: 70%;
          margin-left: auto;
        }

        span {
          width: 30%;
        }
      }
    }
  }

  .services-options-box-1 {
    padding-right: 1rem;
  }

  .services-options-box-2 {
    padding-left: 1rem;
  }
}

.main-title {
  h2 {
    text-align: left;
  }
}

.special-btn {
  margin: 1.5rem 0 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar {
  background-color: legacy-variables.$topBarBackground;
  padding: 0.5rem 1rem 1.5rem 1rem;
  box-shadow: 0 0.9rem 1.5rem rgba(0, 0, 0, 0.2);

  .navbar-header {
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;

    .navbar-brand {
      img {
        max-width: legacy-variables.$topBarLogoSize;
      }
    }

    button {
      margin-left: auto;
      border: 0;

      i.material-icons {
        font-size: 4rem;
        padding: 0;
        margin: 0;
        color: legacy-variables.$topBarBurgerColor;
      }
    }
  }
}

.unit-container {
  .expandible-panel-content {
    ul {
      li {
        display: block !important;

        label {
          width: 29%;
        }

        a {
          color: #6eaf43;
          text-decoration: underline;
          height: auto;
          display: inline-block;
          margin: 0;
          width: 59% !important;
        }

        input,
        select,
        span,
        a {
          width: 69%;
          padding: 0.3rem 0.5rem;
          height: 2.6rem;
        }

        input,
        select {
          border: 0.1rem solid rgba(0, 0, 0, 0.3);
        }

        input[type="checkbox"] {
          width: auto;
          height: auto;
          margin-right: 0.5rem;
        }

        input[type="date"]::-webkit-calendar-picker-indicator {
          color: rgba(0, 0, 0, 0);
          opacity: 1;
          display: block;
          //background: url(../../img/date_icon.jpg) no-repeat;
          background-size: cover;
          width: 1.2rem;
          height: 1.3rem;
          position: relative;
          right: 0.1rem;
          border-width: thin;
        }
      }
    }

    .col-sm-6 {
      padding-right: 1rem !important;

      &:last-of-type {
        background: red !important;
      }
    }
  }
}

.modal-content {
  border-radius: 0.7rem !important;

  .modal-body {
    /*
    p {
      padding: 0 !important;
      font-size: 1.4rem;
    }
    */

    a {
      color: #6eaf43 !important;
      text-decoration: underline;
    }

    pre {
      max-height: 22.5rem;
      border: 0.1rem solid;
      overflow-y: scroll;
      overflow-x: hidden;
      white-space: pre-wrap;
      margin: 1.5rem 0;
      padding: 1rem;
      /*
      font-family: sans-serif;
      font-size: 1.4rem;
      */

      hr {
        width: 97%;
        display: block;
        border-color: rgba(0, 0, 0, 0.4);
        border-width: 0.2rem;
        margin: 1.5rem auto;
      }

      h5 {
        font-size: 1.8rem;
        text-align: center;
      }
    }
  }

  button {
    font-size: 1.4rem;
  }
}

.page-active {
  font-weight: bold;
}

.resident-settings-text-error {
  display: none;
  color: red !important;
  margin-left: 30%;
}

.resident-settings-text-error-1,
.resident-settings-text-error-2 {
  display: none;
  color: red;
}

.modal {
  .modal-header .close {
    // opacity: 1;
    // width: 2.7rem;
    // height: 2.7rem;
    // border-radius: 100%;
    // border: 0.3rem solid #085394;
    // padding: 0;
    // position: absolute;
    // right: 0.5rem;
    // background: white;
    // top: 0.5rem;
    // z-index: 2;
    // transition: all 250ms;
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    top: 0rem;
    cursor: pointer !important;
    font-size: 2.8rem;
    right: 0rem;
    border: 0;
    border-radius: 100% !important;
    background: white;
    opacity: 1;
    font-weight: 500;
    margin: 0 !important;
    padding: 0 !important;
    min-width: unset !important;
    background-color: white !important;
    color: rgba(0, 0, 0, 0.9) !important;
    z-index: 2;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 1 !important;
      border-color: #0b7ada;

      span {
        color: #0b7ada;
      }
    }
  }

  #resetWifiPasswordModal {
    .reset-wifi-modal-footer-part1 {
      button {
        margin: 0.5rem 0;
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
      }
    }
  }

  #resetWifiPasswordModalPart2,
  #printWifiModal {

    .reset-wifi-modal-footer-part2,
    .wifi-modal-options {
      button {
        display: inline-block;
        width: auto;
        padding: 0.3rem 1rem !important;
        margin: 0.5rem 0;
        min-width: 17rem;
      }
    }
  }

  .wifi-inline-modal-options {
    flex-direction: row !important;

    button {
      margin: 1rem 0.5rem !important;
    }
  }

  .reset-wifi-word-ul {
    width: 100%;

    li {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;

      label {
        width: 100%;
        display: flex;
        align-items: center;

        p {
          width: 19%;
        }

        span,
        input {
          width: 79%;
          padding: 0 !important;
          font-size: 1.4rem;
        }
      }
    }
  }

  .panel {
    border-radius: 1rem !important;
    overflow: hidden;
  }
}

.event {
  a {
    background-color: #f6c193 !important;
    color: #000 !important;
  }
}

.navbar-toggler {
  outline: none !important;
}

.index-container {
  .base-button {
    width: 100%;
    margin: 1rem 0;
    text-align: left;
  }
}

.index-residents {
  .base-button {
    background: legacy-variables.$personalBackground !important;
  }
}

.index-public {
  span {
    background: legacy-variables.$guestDarkened !important;
  }

  .base-button {
    background: legacy-variables.$guestBackground !important;
  }
}

.index-property {
  .base-button {
    background: #9bd5f2 !important;

    span {
      background: #7aa4ba !important;
    }
  }
}

.find-resident-interior-content {
  padding-top: 3rem;
  padding-bottom: 3rem;
  font-size: 1.2rem;
}

.odd,
.odd th,
.odd td {
  &:not(:hover) {
      background-color: rgba(0, 0, 0, 0.01) !important;
  }
  /* background-color: rgba(0, 0, 0, 0.01) !important; */
}

.table-title h2 {
  background: white;
  border-bottom: 0;
  border-radius: 1.5rem 1.5rem 0 0;
  position: relative;
  bottom: -3.4rem;
  color: #085394;
  font-size: 1.8rem;
  text-decoration: none;
  padding: 1rem 1.5rem;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  border: 0.1rem solid #ddd;
  padding-right: 3.5rem;
  box-shadow: 0 0 0.1rem 0.1rem rgba(0, 0, 0, 0.05);
}

.dataTables_scroll {
  border: 0.1rem solid #ddd;
}

.table-footer {
  background: white;
  border-top: 0;
  border-radius: 0 0 1.5rem 1.5rem;
  position: relative;
  top: -0.1rem;
  color: #085394;
  font-size: 1.8rem;
  text-decoration: none;
  padding: 1rem 1.5rem;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  border: 0.1rem solid #ddd;
  padding-right: 3.5rem;
  box-shadow: 0 0 0.1rem 0.1rem rgba(0, 0, 0, 0.05);

  .block-color {
    width: 1.5rem;
    height: 1.5rem;
    display: inline-block;
    background: gray;
    margin-right: 0.5rem;
  }

  .access-color {
    background: legacy-variables.$cautionTextBackground !important;
  }

  .lease-color {
    background: legacy-variables.$personalBackground !important;
  }

  .fault-color {
    background: legacy-variables.$errorColor !important;
    /* background: rgb(187, 16, 16) !important; */
  }

  span {
    color: black;
    font-size: 1.4rem;
    display: inline-flex;
    align-items: center;
    margin-right: 1.2rem;
  }
}

.dataTables_filter {
  position: relative;
  margin-top: -0.5rem;
  padding-right: 1.2rem;

  label {
    color: #085394;
    font-style: italic;

    &:before {
      content: "";
      //background: url(../../img/input-lupe.svg);
      width: 2rem;
      height: 2rem;
      background-repeat: no-repeat;
      display: block;
      position: absolute;
      left: -2.3rem;
      background-size: contain;
      top: 0.3rem;
    }

    input {
      border: 0.1rem solid black;
      position: relative;
      top: -0.1rem;
      margin-left: -7rem !important;
      border-radius: 10rem;
      background: transparent;
      padding-left: 1rem;
      padding-right: 0.5rem;
      padding-bottom: 0.5rem;
      padding-top: 0.3rem;

      &:active,
      &:focus,
      &:visited {
        background: white !important;
      }
    }
  }
}

.unit-container .expandible-panel-content .col-sm-6:last-of-type {
  background: transparent !important;
}

/* fallback */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v46/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format("woff2");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}