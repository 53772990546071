//
// Nomadix SCSS post-bootstrap adjustments
//
//

@use "sass:map" as *;
@use "legacy-responsive" as *;
@use "legacy-styles" as *;
@use "legacy-extended" as *;
@use "actiontext" as *;
@use "ez-pre-strap";
@forward "ez-pre-strap";

@use "node_modules/bootstrap/scss/bootstrap" with (
  $link-color: ez-pre-strap.$link-color,
  $theme-colors: ez-pre-strap.$theme-colors,
  $font-size-base: ez-pre-strap.$font-size-base
);

@use "ez-mixins" as *;
@forward "ez-mixins";

$theme-colors: bootstrap.$theme-colors;

//@debug "theme-colors #{$theme-colors}";
.text-aws {
  color: #ff9900;
}

.text-aws-dark {
  color: #252f3e;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--bs-secondary);
}

.must-translate {
  &::before {
    content: "\00a7\2002";
  }
  &::after {
    content: "\2002\00a7";
  }
  background-color: var(--bs-danger);
  color: var(--bs-light);
  font-style: italic;

}

/*
* Use correct colors within disabled link texts.
*/
div,
button:disabled,
a.btn.disabled {
  @include ez-assert-theme-colors($theme-colors: $theme-colors);
}


.first-col,
.second-col,
.third-col {
  border-style: dotted;
  border-color: rgba(0, 0, 0, 0.1);
  border-width: 1px;
}

.badge-gray {
  background-color: var(--bs-gray-500);
}

div.muted {
  background-color: var(--bs-gray-100);
}

div.hanging {
  p {
    margin-left: 2.5rem;
  }
}

label {
  color: var(--ez-body);
}

.form-group.required label::after,
.input-group.required label::after,
label.required::after {
  content: " *";
  color: var(--bs-danger);
  vertical-align: super;
  font-size: smaller;
}

.form-group.optional label::after,
.input-group.optional label::after,
label.optional::after {
  content: " (optional)";
  color: var(--bs-success);
  vertical-align: super;
  font-size: smaller;
  font-style: italic;
}

.form-control::-webkit-input-placeholder {
  color: var(--ez-placeholder);
  opacity: 0.6;
  font-weight: 450;
}

.form-control.placeholder-alert::-webkit-input-placeholder {
  color: var(--bs-danger);
}

/*
* Background image or patterning
*
* This ::before pseudo-element provides the background to the main page container,
* which can be customized through a variety of SCSS variables.
*
* We use a ::before element to limit the scope of such things a opacity, which would
* otherwise affect all container content. This way it applies only to the background.
*/
.ez-container:not(.plain)::before {
  content: "";
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  min-height: ez-pre-strap.$ez-bg-min-height;

  background-image: ez-pre-strap.$ez-bg-image;
  background-size: ez-pre-strap.$ez-bg-size;
  background-repeat: ez-pre-strap.$ez-bg-repeat;
  background-origin: ez-pre-strap.$ez-bg-origin;
  background-position: ez-pre-strap.$ez-bg-position;
  background-blend-mode: ez-pre-strap.$ez-bg-blend-mode;
  opacity: ez-pre-strap.$ez-bg-opacity;
}

.ez-container {
  position: relative;
  background-color: var(--ez-main-bg);
  min-height: 50rem;
  padding-bottom: 7.5rem;
  height: 100%;
}

.ez-container.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.ez-container {
  .ez-banner {
    background-color: var(--white);
    padding-left: 0;
    padding-right: 0;
    box-shadow: 0 0.9rem 1.5rem rgba(0, 0, 0, 0.2);
    position: relative;

    nav.navbar {
      box-shadow: none;
    }

    .top-bar-logo {
      background-image: ez-pre-strap.$ez-brand-image;
      background-repeat: no-repeat;
      background-size: ez-pre-strap.$ez-brand-bg-size;
      min-height: ez-pre-strap.$ez-brand-min-height;
      min-width: ez-pre-strap.$ez-brand-min-width;
    }

    .top-bar-logo {
      margin-left: 1.5rem;
      margin-bottom: 0;
    }

    .top-bar-icons {
      .top-bar-role-sel {
        >.btn {
          border: 0;
        }
      }
    }

    .top-bar-links {
      list-style: none !important;
      text-align: right;

      li {
        display: inline-block;
        font-size: 1.6rem;
        padding: 0.5rem 0.44rem;

        a {
          color: var(--bs-secondary);
          &:active, &:hover {
            color: var(--bs-primary);
          }
        }

        &:last-of-type {
          padding-left: 2rem;

          span {
            display: inline-block;
            font-size: 1.2rem !important;
          }
        }
      }
    }

    .ez-banner-btn {
      span {
        display: inline-block;
        text-align: center;
        margin: 0 1rem;
        cursor: pointer;

        .md-glyph {
          font-size: 3.4rem;
        }
      }
    }

    .collapse.show .ez-banner-btn {
      text-align: left;
      padding: 0;
      font-size: inherit;

      span {
        margin: 0;
        text-align: left;
        cursor: inherit;

        p {
          display: inline-block;
          margin: 0;
        }

        .md-glyph {
          font-size: 1.7rem;
          margin-right: 1rem;
        }
      }
    }

    .breadcrumb-box, .breadcrumb {
      // background-color: var(--bs-gray-200);
      background-color: var(--bs-primary-300)
    }

    .breadcrumb-item + .breadcrumb-item:before {
      // color: var(--bs-secondary);
      color: var(--bs-gray-100);
    }

    .breadcrumb-item a {
      color: var(--bs-gray-100);
    }

    .breadcrumb-item.active {
      color: var(--bs-gray-200);
    }
  }

  .ez-interior {
    margin-top: 2.4rem;
  }

  .ez-interior,
  .ez-interior-frame {
    position: relative;
    min-height: ez-pre-strap.$ez-content-min-height;
    background-color: rgba(255, 255, 255, .8);

    .main-title {
      color: var(--bs-secondary);
      margin-top: 2.5rem;
      margin-bottom: 1.5rem;
    }

    div.timeline {
      width: 100%;
    }

    /* Those pesky in-line switches do not line-up with other form groups */
    .input-group {

      .form-check-inline,
      .custom-control-inline {
        border: 1px solid;
        border-color: rgba(0, 0, 0, 0.1);
        padding-top: 0.375rem;
        padding-bottom: 0.375rem;
      }

      span {
        padding-top: 0.375rem;
        padding-right: 0.375rem;
      }
    }
  }

  /*
  * signin transparency
  *
  * This can be used to control background transparency on the signin bloc to
  * allow any underlying background image or pattern to remain visible, although
  * at reduced opacity.
  *
  * As with the main container, this is accomplished using a positioned ::before
  * pseudo-element so that any opacity we define is not inherited by the container
  * content, but only applied to its background.
  */
  .ez-signin::before {
    content: "";
    position: ez-pre-strap.$ez-signin-bg-posn;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: ez-pre-strap.$loginBackground;
    background-origin: border-box;
    opacity: ez-pre-strap.$ez-signin-bg-opacity;
  }

  .ez-signin {
    border-radius: 0.6rem;
    box-shadow: 0 0.5rem 1rem 0.5rem rgba(0, 0, 0, 0.2);
    margin-top: 4rem;
    padding-top: 2rem;
    border: 0.1rem solid rgba(0, 0, 0, 0.3);
    position: relative;

    >div {
      position: relative;
    }
  }

  .ez-interior,
  .ez-signin {
    .signin-title {
      text-align: center;
      margin-top: 2.5rem;
      margin-bottom: 1.5rem;

      h2 {
        text-transform: uppercase;
      }

      h2,
      h3 {
        color: var(--ez-login-title);
        font-size: 3rem;
        margin-top: 0;
      }

      h4 {
        color: var(--ez-login-text);
        font-size: 2.2rem;
        margin-top: 2.5rem;
      }

      >div {
        margin-top: 2.5rem;
        margin-bottom: 1.5rem;
        text-align: left;
      }
    }

    .signin-button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2rem 0;

      button,
      a.logout {
        margin-left: 0;
        border: 0.1rem solid;
        position: relative;
        right: unset;
        bottom: unset;
        padding: 0.5rem 3.5rem;
        border-radius: 0.7rem;
      }
    }

    .form-check-label {
      margin-left: 0.75rem;
    }
  }

  .card.ez-card {
    position: relative;
    margin-top: 1rem;
    margin-bottom: 1rem;

    .card-header {
      background: var(--bs-gray-100);
      color: var(--bs-secondary);

      .md-glyph.expand-arrow,
      .material-icons.expand-arrow {
        position: absolute;
        right: 0.8rem;
        top: 0rem;
        font-size: 3.5rem;
        transition: all 250ms;
      }

      &[data-toggle="collapse"] {
        cursor: pointer;
      }

      &.collapsed .expand-arrow,
      a.collapsed .expand-arrow {
        transform: rotate(-90deg);
      }

      @include bootstrap.media-breakpoint-down(sm) {
        .badge {
          white-space: unset;
        }
      }
    }

    .collapse {
      .card-body {
        background: var(--white);

        .ez-card-content {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    @include bootstrap.media-breakpoint-down(sm) {
      .expand-arrow {
        display: none;
      }
    }
  }

  .ez-card.ez-rsdt-wifi {

    .wifi-location,
    .wifi-state {
      font-size: 1.4rem;
      margin-bottom: 1rem;
    }

    .wifi-location {
      font-weight: bolder;

      .md-glyph {
        color: var(--red);
      }
    }

    .wifi-settings {
      table tr td:first-of-type {
        /* min-width: 11rem; */
        font-weight: bolder;
      }

      ul {
        margin: 0;
        padding-inline-start: unset;

        li {
          color: var(--bs-gray-700);
          margin-bottom: 0.5rem;
          margin-right: 0.5rem;
          display: inline-block;
        }

        li:first-of-type {
          min-width: 11rem;
          font-weight: bolder;
        }
      }
    }

    .qr-code {
      min-width: 150px;

      svg {
        margin-left: 5.5rem;
      }
    }

    .special-btn {
      margin: 2.5rem 0 1rem 0;
      display: flex;
      justify-content: center;

      button {
        padding: 1rem;
        padding-left: 5rem;
        position: relative;
        border-radius: 0.7rem;
        margin-top: 0;
        font-size: 1.6rem;

        span {
          position: absolute;
          left: 0.1rem;
          top: 3%;
          border-radius: 0.5rem;
          height: 94%;
          overflow: hidden;
          padding: 0.5rem;

          .md-glyph {
            font-size: 3.6rem;
            margin-left: -0.5rem;
            margin-top: -0.1rem;
            margin-right: -0.1rem;
            opacity: 0.9;
          }
        }
      }
    }

    .modal {
      .modal-content {
        .modal-body {
          span.personal-color {
            display: inline;
          }

          .passphrase-notice {
            margin: 1.5rem 0;
          }

          .passphrase-form {
            .input-group {
              .input-group-prepend>.input-group-text {
                padding-right: 0;
              }

              input.form-control {
                padding-left: 0;
              }
            }
          }

          .passphrase-review {
            margin: 1.5rem 0;

            .passphrase-new {
              margin: 1rem 0;
              padding: 1rem;
              border-style: solid;
            }
          }
        }
      }
    }
  }

  /*
  * .flat is a .modal that isn't: where there is a modal structure but
  * the class prefix 'modal' is replaced by 'flat'.
  *
  */
  .flat {
    .flat-content {
      .flat-footer {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .ez-card.ez-rsdt-acct {

    //
    // These are legacy styles that I've roped-in temporarily, with a view to
    // updating after POC.
    ul {
      margin: -0.6rem 0 !important;

      li {
        display: block !important;
        margin: 0.5rem 0;

        label {
          width: 29%;
        }

        input {
          border: 0.1rem solid rgba(0, 0, 0, 0.3);
          width: 65%;
          padding: 0.3rem 0.5rem;
          height: 2.6rem;
        }

        .unit-a101-checkboxes {
          label {
            width: auto;
            padding-left: 30%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }

          input[type="checkbox"] {
            width: auto;
            height: auto;
            margin-right: 0.5rem;
          }
        }
      }

      li:first-of-type {
        min-width: 9rem;
      }
    }
  }

  .ez-card.ez-rsdt-cap {
    .caution {
      border: 0.2rem solid var(--bs-warning);
      position: relative;
      padding: 0;
      border-radius: 0.7rem;
      overflow: hidden;
      min-height: 10rem;
      margin: 1.5rem 0;
      margin-bottom: 3rem !important;

      .caution-title {
        background-color: var(--white);

        h5 {
          color: var(--bs-warning);
          font-size: 1.6rem;
          margin: 0.6rem 0;
          font-weight: bold;
          text-align: center;
        }
      }

      .caution-content {
        background-color: var(--bs-warning);

        p {
          padding: 0.5rem;
          margin: 0;
          color: var(--white) !important;
        }
      }

      .caution-bg {
        background-color: var(--bs-warning);
        height: 100%;
        position: absolute;
        width: 100%;
        z-index: 1;
        left: 0;
      }
    }
  }

  .ez-card.ez-rsdt-faq {
    .panel-body {

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: var(--bs-primary);
        font-weight: bolder;
        margin-bottom: 1rem;
      }
    }
  }

  div.aup-content {
    height: 40rem;
    overflow: auto;
  }

  .form-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;

    button {
      margin-left: 0;
      border: 0.1rem solid;
      position: relative;
      right: unset;
      bottom: unset;
      padding: 0.5rem 3.5rem;
      border-radius: 0.7rem;
    }
  }
}

.fatboy,
.base-button {
  padding: 1rem;
  padding-left: 5rem;
  position: relative;
  border-radius: 0.7rem;
  color: var(--white);
  background: var(--bs-gray-500);
  font-size: 1.6rem;
  transition: all 250ms;

  &:hover {
    color: var(--white) !important;
  }

  width: 100%;
  margin: 1rem 0;
  text-align: left;

  span {
    position: absolute;
    left: 0.1rem;
    top: 3%;
    border-radius: 0.5rem;
    height: 94%;
    overflow: hidden;
    padding: 0.5rem;
    background: var(--ez-personal-dark);
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      // transform: rotate(45deg);
      font-size: 3.6rem;
      margin-left: -0.2rem;
      margin-top: -0.1rem;
      margin-right: -0.1rem;
      opacity: 0.9;
    }

    img {
      min-width: 3rem;
    }
  }

  &.fb-1 {
    color: var(--ez-gamut-1-tx);
    background: var(--ez-gamut-1-lt);

    span {
      background: var(--ez-gamut-1-dk);
    }
  }

  &.fb-2 {
    color: var(--ez-gamut-2-tx);
    background: var(--ez-gamut-2-lt);

    span {
      background: var(--ez-gamut-2-dk);
    }
  }

  &.fb-3 {
    color: var(--ez-gamut-3-tx);
    background: var(--ez-gamut-3-lt);

    span {
      background: var(--ez-gamut-3-dk);
    }
  }

  &.fb-4 {
    color: var(--ez-gamut-4-tx);
    background: var(--ez-gamut-4-lt);

    span {
      background: var(--ez-gamut-4-dk);
    }
  }

  &.fb-5 {
    color: var(--ez-gamut-5-tx);
    background: var(--ez-gamut-5-lt);

    span {
      background: var(--ez-gamut-5-dk);
    }
  }
}

.ez-small {
  font-size: ez-pre-strap.$ez-font-size-small;

  .input-group-text,
  .form-control {
    font-size: ez-pre-strap.$ez-font-size-small;
  }
}

span.badge {
  &.green {
    background: ez-pre-strap.$personalBackground;
  }

  &.blue {
    background: #9bd5f2;
  }
}

.md-glyph-outlined,
.md-glyph-two-tone,
.md-glyph-round,
.md-glyph-sharp,
.md-glyph-filled,
.md-glyph {
  font-family: "Material Icons Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 1.7rem;
  letter-spacing: normal;
  text-transform: none;
  display: inline-flex;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;

  align-items: center;
  justify-content: center;
  vertical-align: middle;

  &.md-18 {
    font-size: 18px;
  }

  &.md-24 {
    font-size: 24px;
  }

  &.md-36 {
    font-size: 36px;
  }

  &.md-48 {
    font-size: 48px;
  }

  &.md-big {
    font-size: 3.4rem;
  }

  &.rot-n {
    transform: none;
  }

  &.rot-ne {
    transform: rotate(45deg);
  }

  &.rot-e {
    transform: rotate(90deg);
  }

  &.rot-sw {
    transform: rotate(135deg);
  }

  &.rot-s {
    transform: rotate(180deg);
  }

  &.rot-sw {
    transform: rotate(225deg);
  }

  &.rot-w {
    transform: rotate(270deg);
  }

  &.rot-nw {
    transform: rotate(315deg);
  }

  &.blank {
    opacity: 0;
  }
}

//
// This class is typically used to make scrolling 'notify' boxes on forms,
// to capture
.scrollify, .scrollify-lg, .scrollift-xl {
  overflow: auto;
  max-height: 30rem;
}

.scrollify-lg {
  max-height: 60rem;
}

.scrollify-xl {
  max-height: 90rem;
}

.slidewide {
  white-space: nowrap;
  overflow: auto;
}

//
// Alternate Material Icon font family selection: these are NOT
// supported on IE
@supports not (-ms-high-contrast: none) {
  .md-glyph-filled {
    font-family: "Material Icons";
  }

  .md-glyph-outlined {
    font-family: "Material Icons Outlined";
  }

  .md-glyph-two-tone {
    font-family: "Material Icons Two Tone";
  }

  .md-glyph-round {
    font-family: "Material Icons Round";
  }

  .md-glyph-sharp {
    font-family: "Material Icons Sharp";
  }
}

h1,
h2,
h3,
h4,
h5 {

  &>.md-glyph-two-tone,
  &>.md-glyph-outlined,
  &>.md-glyph-round,
  &>.md-glyph-sharp,
  &>.md-glyph {
    font-size: inherit;
  }
}

button.clip-btn {
  margin-left: 1rem;
}

.navbar-collapse.show {
  position: inherit;
}

@media screen and (max-width: 575px) {
  .top-bar-links {
    li {
      a {
        display: block;
        margin: 0.8rem 0 0.2rem 1.5rem;

        &:first-of-type {
          margin: 0 !important;
        }
      }
    }
  }
}

//
// Subscription wifi status indicators
// .table-access-blocked,
.table-access-blocked th.shaded,
.table-access-blocked td.shaded {
  background: rgba(255, 109, 0, 0.5) !important;
  color: white !important;
}

// .table-lease-pending,
.table-lease-pending th.shaded,
.table-lease-pending td.shaded {
  background: rgba(134, 197, 169, 0.5) !important;
}

// .table-lease-pending,
.table-fault th.shaded,
.table-fault td.shaded {
  background: rgba(187, 16, 16, 0.5) !important;
  color: white !important;
}

.table-expired th.shaded,
.table-expired td.shaded {
  background: rgba(var(--bs-gray-400-rgb), 0.3) !important;
  color: bootstrap.$gray-900  !important;
  font-style: italic;
}

//
// Coloring for AUP versions table
tr.aupv,
tr.aupv-draft,
tr.aupv-deprecated,
tr.aupv-published {
  &.aupv-draft {
    td.shaded {
      opacity: inherit;
    }
  }

  &.aupv-deprecated {
    td.shaded {
      opacity: 0.5;
    }
  }

  &.aupv-published {
    td.shaded {
      color: rgba(187, 16, 16, 1);
    }

    &.aupv-live>td.shaded {
      background: rgba(134, 197, 169, 0.5) !important;
      font-weight: bold;
      color: var(--ez-body);
    }

    &.aupv-live-at>td.shaded {
      background: rgba(134, 197, 169, 0.25) !important;
      font-style: italic;
      color: var(--ez-body);
    }
  }
}

div.input-group-prepend.matching,
div.input-group-append.matching,
div.input-group-prepend.clickable,
div.input-group-append.clickable,
div.input-group.locking,
div.input-group.flatpickr {
  position: relative;

  input[type="text"] {
    position: relative;
    z-index: 2;
  }

  img {
    position: absolute;
    right: 0.7rem;
    top: 0.3rem;
    z-index: 1;
  }

  .input-group-text {
    padding-top: 0;
    padding-bottom: 0;
    background-color: inherit;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    //background: url(../../img/date_icon.jpg) no-repeat;
    background-size: cover;
    width: 1.2rem;
    height: 1.3rem;
    position: relative;
    right: 0.1rem;
    border-width: thin;
  }
}

div.input-group.f-m-l {
  >.form-control {
    &:first-child {
      flex-grow: 2;
    }

    &:last-child {
      flex-grow: 4;
    }
  }

  >.form-feedback {
    text-align: center;
  }
}

div.input-group.c-r-l {
  >.form-control {
    flex-grow: 4;

    &:last-child {
      flex-grow: 1;
    }
  }
}

div.input-group.p-e {
  >div.iti {
    flex-grow: 1;
  }

  >.form-control {
    flex-grow: 4;
  }
}

div.form-inline.y-m-w-d {
  .input-group-text {
    font-size: ez-pre-strap.$ez-font-size-small;
  }

  div.invalid-feedback {
    text-align: left;
  }
}

@media (max-width: 768px) {
  div.input-group.p-e {
    display: block;

    >div.iti,
    >.form-control {
      display: block;
      width: 100%;
    }
  }
}

.autocomplete-selected {
  background: map-get($theme-colors, "secondary");
  color: map-get($theme-colors, "light");
}

.autocomplete-suggestion {
  cursor: pointer;
  overflow: hidden;
  padding: 3px 20px;
  white-space: nowrap;
}

.autocomplete-suggestions {
  background: var(--ez-dropdown-bg);
  border: 1px solid rgba(var(--ez-dropdown-border-rgb), 0.15);
  overflow: auto;
  padding: 5px 0;
}

.clickable {
  cursor: pointer;
}

trix-editor {
  min-height: 30rem;
  overflow-y: auto;
}

.badged-right {

  &.badge,
  .badge {
    float: right;
  }
}

/* Custom control hacking */
.custom-control-label::before,
.custom-control-label::after {
  width: 1.25rem;
  height: 1.25rem;
}

.custom-checkbox {

  .custom-control-label::before,
  .custom-control-label::after {
    transform: scale(1.2);
  }
}

.custom-control-input.custom-danger:checked {
  &~ ::before {
    border-color: var(--bs-danger);
    background-color: var(--bs-danger);
  }

  &~label {
    color: var(--bs-danger);
  }
}

.custom-control-input.custom-warning:checked {
  &~ ::before {
    border-color: var(--bs-warning);
    background-color: var(--bs-warning);
  }

  &~label {
    color: var(--bs-warning);
  }
}

.custom-control-input.custom-primary:checked {
  &~ ::before {
    border-color: var(--bs-primary);
    background-color: var(--bs-primary);
  }

  &~label {
    color: var(--bs-primary);
  }
}

.custom-control-input.custom-secondary:checked {
  &~ ::before {
    border-color: var(--bs-secondary);
    background-color: var(--bs-secondary);
  }

  &~label {
    color: var(--bs-secondary);
  }
}

.custom-checkbox .custom-control-input:disabled {
  &~.custom-control-label::before {
    border-color: var(--bs-gray-600);
    opacity: 0.5;
  }

  &:checked~.custom-control-label::before {
    background-color: var(--bs-gray-600);
  }
}

/* In-table checkbox styling */
.tabsel {
  cursor: pointer;

  >input[type="checkbox"] {
    display: none;
  }
}

/* International Telephone numbers */

div.form-group {
  .iti-flag {
    background-image: url("../../../node_modules/intl-tel-input/build/img/flags.png");
  }

  @media (-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 192dpi) {
    .iti-flag {
      background-image: url("../../../node_modules/intl-tel-input/build/img/flags@2x.png");
    }
  }

  /*
  .intl-tel-input {
  }
  */

  div.input-group:not(.p-e) {
    div.iti {
      width: inherit;

      div.iti__selected-flag {
        height: 2.8rem;
      }
    }
  }
}

div.dataTables_wrapper {
  margin-top: 1rem;
}

// LA
// ScrollBar fix can be widened if needed

div.sp-properties-table div.dataTables_scrollBody {
  overflow-y: auto !important;
  overflow-x: hidden !important;

  @media (max-device-width: 768px) {
    overflow-x: auto !important;
  }
}

/* bootstrap4-toggle
*/
.btn-xs {
  font-size: ez-pre-strap.$ez-font-size-small;
}

.btn.disabled,
.btn:disabled {
  border-color: var(--bs-gray-300);
  background-color: var(--bs-gray-300);
}

img.portrait {
  width: 40px;
  height: 40px;
  background-color: var(--bs-gray-200);
}

.conceal:not(.show) {
  div {
    background-color: var(--bs-gray-400);
    opacity: 0.75;
  }
}

/* Stripes: stolen from here: http://stripesgenerator.com/ */
$lt-stripe: rgba(245, 230, 21, 0.2);
$dk-stripe: rgba(95, 83, 112, 0.1);

.super-only,
input.safe.xxx {
  /* background-color: black; */
  background-image: linear-gradient(45deg,
      $lt-stripe 25%,
      $dk-stripe 25%,
      $dk-stripe 50%,
      $lt-stripe 50%,
      $lt-stripe 75%,
      $dk-stripe 75%,
      $dk-stripe 100%);
  background-size: 22.63px 22.63px;
}

/*
*/
.pad-off,
.pad-l-off,
.pad-h-off {
  padding-left: 0;
}

.pad-off,
.pad-r-off,
.pad-h-off {
  padding-right: 0;
}

.pad-off,
.pad-t-off,
.pad-v-off {
  padding-top: 0;
}

.pad-off,
.pad-b-off,
.pad-v-off {
  padding-bottom: 0;
}

/*
* Issues on android viewing toggle buttons after they have been switched off.
* Can be partly resolved by ensuring background color is properly set to white.
*/
.toggle-off.android_fix {
  background-color: white;
  color: var(--bs-primary);
}

/* Modal max-width for form editing */
@media (min-width: 576px) {
  div.modal-form div.modal-dialog {
    max-width: 55%;
  }
}

/* # Report css */
.report {
  overflow: hidden;
  overflow-x: scroll;

  table {
    table-layout: fixed;
    min-width: 850px;
  }
}

/* page spinner use */
#overlay {
  background: #ffffff;
  color: #666666;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 5000;
  top: 0;
  left: 0;
  float: left;
  text-align: center;
  padding-top: 25%;
  opacity: 0.8;
}

#overlay-modal {
  background: #ffffff;
  color: #666666;
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 5000;
  top: 0;
  left: 0;
  float: left;
  text-align: center;
  /*padding-top: 25%;*/
  opacity: 0.8;
}

#loader {
  width: 10rem;
  height: 10rem;
}

/* Mobile table support for devices */
@media only screen and (min-width: 1200px) {
  #flip-scroll {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  #full-device-view {
    display: none;
  }

  #flip-scroll .cf:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }

  #flip-scroll * html .cf {
    zoom: 1;
  }

  #flip-scroll *:first-child+html .cf {
    zoom: 1;
  }

  #flip-scroll table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }

  #flip-scroll th,
  #flip-scroll td {
    margin: 0;
    vertical-align: top;
  }

  #flip-scroll th {
    text-align: left;
  }

  #flip-scroll table {
    display: block;
    position: relative;
    width: 100%;
  }

  #flip-scroll thead {
    display: block;
    float: left;
  }

  #flip-scroll tbody {
    display: block;
    width: auto;
    position: relative;
    overflow-x: auto;
    white-space: nowrap;
  }

  #flip-scroll thead tr {
    display: block;
  }

  #flip-scroll th {
    display: block;
    text-align: right;
  }

  #flip-scroll tbody tr {
    display: inline-block;
    vertical-align: top;
  }

  #flip-scroll td {
    display: block;
    min-height: 1.25em;
    text-align: left;
  }

  /* sort out borders */

  #flip-scroll th {
    border-bottom: 0;
    border-left: 0;
  }

  #flip-scroll td {
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
  }

  #flip-scroll tbody tr {
    border-left: 1px solid var(--bs-gray-400);
  }

  #flip-scroll th:last-child,
  #flip-scroll td:last-child {
    border-bottom: 1px solid var(--bs-gray-300);
  }
}

/* Logo span control */
.logo-span {
  display: flex;
  justify-content: center;
}

//
// Theming tools and bootstrap CSS hacks.
input[type="color"] {
  max-width: 12rem;
}

// datatables js appears to not word-break on
// some table data fields in mobile devices...
// If you are looking at a phone editing this well
// expect the consequense.  The other option would be to
// hide columns on mobile devices or truncate fields
// 1-22-24 LA normal displays and tablets should just use the
// break-word setting and displays that are just too small
// should break on word


@media (min-width: 768px) {
  table.dataTable td {
    // word-break: break-all;
    // I think that word break is necessary here perhaps a media query
    // for mobile devices
    word-break: break-word;
  }
}

// Might even at some point make sense to
@media (max-width: 576px) {
  table.dataTable td {
    // word-break: break-all;
    word-break: break-all;
    white-space: nowrap;
  }
}

//
//
.nav-tabs {
  .nav-link {
    &.active, :active, :hover {
      color: var(--bs-primary);
    }
  }
}


/// Cluster Multi-select CSS Start ///
// Card style
.card {
  border: 0 solid #edf2f9;
  border-radius: .375rem;
  -webkit-box-shadow: 0 7px 14px 0 rgba(59, 65, 94, .1), 0 3px 6px 0 rgba(0, 0, 0, .07);
  box-shadow: 0 7px 14px 0 rgba(59, 65, 94, .1), 0 3px 6px 0 rgba(0, 0, 0, .07);
}

.card-body {
  padding: .5rem;
  background-color: #f9fafd !important;
}

.card-header {
  padding: 1rem 1.25rem;
  background-color: #fff;
  border-bottom: 0 solid #edf2f9
}


// Sortable items
.dragableMultiselect {
  display: none;
}

.dragSortableItems {
  .sortable-list {
    list-style: none;
    margin: 0;
    min-height: 20px;
    padding: 0px;
  }

  .sortable-item {
    background-color: #fff;
    border: 1px solid #ddd;
    display: block;
    margin-bottom: -1px;
    padding: 10px;
    cursor: move;
    position: relative;
    padding-left: 30px;

    .icon-drag {
      color: #ccc;
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }

    .sortable-item-input {
      visibility: hidden;
      pointer-events: none;
      position: absolute;
    }
  }

  // Item placeholder (visual helper)
  .placeholder {
    border: 1px dashed #666;
    height: 45px;
    margin-bottom: 5px;
  }

  .fixed-panel {
    max-height: 500px;
    overflow-y: auto;
    padding-bottom: 1px;
  }
}



// Scrollbar Style
.custom-scrollbar::-webkit-scrollbar {
  width: 7px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  // background: transparent;
  border-radius: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}




input.label-input {
  background: transparent !important;
  border: none !important;
  outline: none !important;
  padding: 0px 0px 0px 0px !important;
  font-weight: normal;
  font-style: normal;
  font-size: 1.7rem;
  resize: both;
  width: auto;
}

input.wifi-dpsk-input::placeholder {
  font-size: 1.1rem;
}
/// Cluster Multi-select CSS End ///

.attachment__caption {
  display: none !important;
}

@include ez-override-bs-color-classes($theme-colors: $theme-colors);
//@include ez-override-bs-color-2-classes($theme-colors: $theme-colors);

// permission matrix responsive tweaks

@media (min-width: 576px) and (max-width: 992px){
  .permission-matrix-admin, .permission-matrix-profile{
    margin-top: 10rem;
    margin-left: -25rem;
  }

  .permission-matrix-profile{
    margin-top: 25rem;
  }

  .first-col, .second-col, .third-col{
    border-style: none;
  }
}

// NSE Fallback IP CSS
.fallback-add-link{
  font-size: .80em;
}
#fallback-ip-div{
  max-height: 8em;
  overflow-x: hidden;
  overflow-y: scroll;
  border: 1px solid #cccccc;
  padding-left: 1rem;
}

#pms_pu_div{
  max-height: 50em;
  overflow-x: hidden;
  overflow-y: scroll;
}

#fallback-ip-div::-webkit-scrollbar, #pms_pu_div::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

#fallback-ip-div::-webkit-scrollbar-thumb, #pms_pu_div::-webkit-scrollbar {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}


#fallback-ip-div input.deleted {
  text-decoration: line-through;
  border-color: red;
}

// datatables loading
div.dataTables_processing > div:last-child > div {
  background: var(--bs-dt-spinner-color);
}

// Vertical alignment for datatable cells should always be Top.
table.dataTable > tbody > tr > td {
  vertical-align: top !important;
}

// Collapse icons toggle
// include both expand and contract icons
// and name icons accordingly
.icon-collapse-toggle {
  &[data-toggle="collapse"] {
    cursor: pointer;
  }

  &[aria-expanded=true] .expand-icon {
    display: none;
  }

  &[aria-expanded=false] .contract-icon {
    display: none;
  }
}

.sort-index-icons {
  font-size: x-large;
}

div.progress-bar {
  &.bg-primary {
    background-color: var(--bs-primary) !important;
  }

  &.bg-secondary {
    background-color: var(--bs-secondary) !important;
  }
}

.pay-tran-content {
  max-height: 55rem;
  overflow: auto;
}

.promotion-opt-odd {
  background-color: white;
}

.promotion-opt-even {
  background-color: #F2F3F4;
}